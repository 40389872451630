import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BandeirasComponent } from './bandeiras/bandeiras.component';
import { MaquininhasComponent } from './maquininhas/maquininhas.component';

const routes: Routes = [

  {path: '', pathMatch: 'full', redirectTo: 'maquininhas'},

  {path: 'maquininhas', component: MaquininhasComponent},

  {path: 'bandeiras', component: BandeirasComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaixaRoutingModule { }
