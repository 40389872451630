import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CaixaRoutingModule } from './forma-pagamento-routing.module';

import { ToastModule } from 'primeng/toast';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { MaquininhasComponent } from './maquininhas/maquininhas.component';
import { BandeirasComponent } from './bandeiras/bandeiras.component';
import { ComponentsModule } from '@app/components/components.module';
import { UtilModule } from '@app/util/util.module';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    MaquininhasComponent,
    BandeirasComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    ComponentsModule,
    UtilModule,
    CaixaRoutingModule,
    AutocompleteLibModule,
    Ng2SearchPipeModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot()
  ]
})
export class FormaPagementoModule { }
